export default class ParameterExemple {
    constructor() {
        this.d = {
            comum: {
                dataAtual: "15/10/2022",
                dataAtualExtenso: "sábado, 15 outubro 2022",
                horaAtual: "17:00"
            },
            locacao: {
                numero: "900",
                dataEfetivacao: "14/10/2022",
                dataCadastro: "14/10/2022",
                horaCadastro: "16:00",
                identificacao: "Locação Evento Brasil",
                tipoLocacao: "Evento Interno",
                obsLocacao: "Valido por 7 dia(s)",
                obsOs: "Entregar para Sr João",
                total: "R$ 1.600,00",
                totalExtenso: "Um Mil e Seiscentos Reais",
                totalCusto: "R$ 800,00",
                totalCustoExtenso: "Oitocentos Reais",
                totalReposicao: "R$ 20.000,00",
                totalReposicaoExtenso: "Vinte Mil Reais",
                totalDesconto: "R$ 100,00",
                totalDescontoExtenso: "Cem Reais",
                totalSeguro: "R$ 20,00",
                totalSeguroExtenso: "Vinte Reais",
                totalGeral: "R$ 1.520,00",
                totalGeralExtenso: "Um Mil e Quinhentos E Vinte Reais",
                cliente: {
                    razaoSocialNome: "Grupo Locadora Exemplo Ltda",
                    fantasiaApelido: "Locadora Exemplo",
                    cnpjCpfTitulo: "CNPJ",
                    cnpjCpf: "00.000.000/0001-00",
                    irTitulo: "Inscrição Estadual",
                    ir: "000.000.000-00",
                    im: "000.000.000-00",
                    cep: "00000-000",
                    rua: "Rua do Cliente 1",
                    numero: "10",
                    complemento: "Galpão 1",
                    bairro: "Chacará",
                    cidade: "Cotia",
                    estado: "SP",
                    telefone: "(00) 0000-0000",
                    site: "www.locadoraexemplo.com.br",
                    email: "email@locadoraexemplo.com.br",
                    obs: "Cliente 5 estrelas",
                    endereco: [
                        { seq: "1", descricao: "Principal", cep: "00000-000", rua: "Rua do Cliente 1", numero: "10", complemento: "Galpão 1", bairro: "Chacará", cidade: "Cotia", estado: "SP" },
                    ],
                },
                colaborador: {
                    nome: "João da Silva",
                    apelido: "João",
                    celular: "(00) 9000-0000",
                    email: "email@consultor.com.br"
                },
                contato: {
                    nome: "Maria da Silva",
                    apelido: "Maria",
                    celular: "(00) 9000-0000",
                    email: "email@contato.com.br"
                }
            },
            os: {
                numero: "900",
                dataCadastro: "14/10/2022",
                horaCadastro: "16:00",
                identificacao: "Locação Evento Brasil",
                observacoes: "Valido por 7 dia(s)",
                total: "R$ 1.600,00",
                totalExtenso: "Um Mil e Seiscentos Reais",
                cliente: {
                    razaoSocialNome: "Grupo Locadora Exemplo Ltda",
                    fantasiaApelido: "Locadora Exemplo",
                    cnpjCpfTitulo: "CNPJ",
                    cnpjCpf: "00.000.000/0001-00",
                    irTitulo: "Inscrição Estadual",
                    ir: "000.000.000-00",
                    im: "000.000.000-00",
                    cep: "00000-000",
                    rua: "Rua do Cliente 1",
                    numero: "10",
                    complemento: "Galpão 1",
                    bairro: "Chacará",
                    cidade: "Cotia",
                    estado: "SP",
                    telefone: "(00) 0000-0000",
                    site: "www.locadoraexemplo.com.br",
                    email: "email@locadoraexemplo.com.br",
                    obs: "Cliente 5 estrelas",
                    endereco: [
                        { seq: "1", descricao: "Principal", cep: "00000-000", rua: "Rua do Cliente 1", numero: "10", complemento: "Galpão 1", bairro: "Chacará", cidade: "Cotia", estado: "SP" },
                    ],
                },
            },
            fatura: {
                numero: "20",
                dataEmissao: "20/10/2022",
                obs: "Referente a Locação 42",
                totalDesconto: "R$ 100,00",
                totalDescontoExtenso: "Cem Reais",
                total: "R$ 1.000.00",
                totalExtenso: "Um mil reais",
                totalGeral: "R$ 1.520,00",
                totalGeralExtenso: "Um Mil e Quinhentos E Vinte Reais",
                cliente: {
                    razaoSocialNome: "Grupo Locadora Exemplo Ltda",
                    fantasiaApelido: "Locadora Exemplo",
                    cnpjCpfTitulo: "CNPJ",
                    cnpjCpf: "00.000.000/0001-00",
                    irTitulo: "Inscrição Estadual",
                    ir: "000.000.000-00",
                    im: "000.000.000-00",
                    cep: "00000-000",
                    rua: "Rua do Cliente 1",
                    numero: "10",
                    complemento: "Galpão 1",
                    bairro: "Chacará",
                    cidade: "Cotia",
                    estado: "SP",
                    telefone: "(00) 0000-0000",
                    site: "www.locadoraexemplo.com.br",
                    email: "email@locadoraexemplo.com.br",
                    obs: "Cliente 5 estrelas",
                    endereco: [
                        { seq: "1", descricao: "Principal", cep: "00000-000", rua: "Rua do Cliente 1", numero: "10", complemento: "Galpão 1", bairro: "Chacará", cidade: "Cotia", estado: "SP" },
                    ],
                },
            },
            periodoLocacao: [{
                seq: "1",
                dataEntrega: "17/10/2022",
                horaEntrega: "8:00",
                dataInicio: "17/10/2022",
                horaInicio: "9:00",
                dataFim: "20/10/2022",
                horaFim: "18:00",
                dataRetirada: "20/10/2022",
                horaRetirada: "20:00",
                ano: "1",
                mes: "2",
                dia: "3",
                hora: "5",
                minuto: "35",
                periodoCondicional: "1 ano 4 meses 3 dias"
            },
            {
                seq: "2",
                dataEntrega: "19/10/2022",
                horaEntrega: "10:00",
                dataInicio: "19/10/2022",
                horaInicio: "11:00",
                dataFim: "28/10/2022",
                horaFim: "22:00",
                dataRetirada: "30/10/2022",
                horaRetirada: "23:00",
                ano: "0",
                mes: "3",
                dia: "3",
                hora: "5",
                minuto: "20"
            }],
            itensFatura: [{
                descricao: "Referente a Locação de Equipamentos",
                qtd: 2,
                valorUnit: "R$ 100,00",
                valorTotal: "R$ 180,00",
                valorDescUnit: "R$ 10,00",
                valorDescTotal: "R$ 20,00",
                valorUnitComDesconto: "R$ 10,00",
            },
            {
                descricao: "Referente a Locação de Equipamentos",
                qtd: 2,
                valorUnit: "R$ 100,00",
                valorTotal: "R$ 180,00",
                valorDescUnit: "R$ 10,00",
                valorDescTotal: "R$ 20,00",
            }],
            itensLocacao: [
                {
                    seq: "1",
                    urlFoto: "https://espaco.blob.core.windows.net/nixloc-photo-product/noImage.jpg",
                    grupo: "Equipamentos",
                    categoria: "Vídeo",
                    tipo: "Betoneira",
                    modelo: "400 Litros",
                    acessorio: "Tripé 2Mts (4) - Cabo de Energia 20Mts (4)",
                    patrimonio: "P442685 - P885549",
                    descricao: "A betoneira é um equipamento utilizado em canteiro de obra para realizar a mistura de concreto e argamassa. Confira o que são betoneiras, tipos e tamanhos. A betoneira é um equipamento utilizado em canteiros de obras para realizar a mistura de concreto e argamassa.",
                    qtd: 2,
                    qtdLocado: 2,
                    qtdRetornado: 2,
                    unMedida: "Pça",
                    valorUnit: "R$ 100,00",
                    valorTotal: "R$ 180,00",
                    valorDescUnit: "R$ 10,00",
                    valorDescTotal: "R$ 20,00",
                    porcenDescUnit: "10%",
                    valorSeguroUnit: "R$ 5,00",
                    valorSeguroTotal: "R$ 10,00",
                    valorReposicaoUnit: "R$ 4.500,00",
                    valorReposicaoTotal: "R$ 9.000,00",
                    dataEntrega: "17/10/2022",
                    horaEntrega: "8:00",
                    dataInicio: "17/10/2022",
                    horaInicio: "9:00",
                    dataFim: "20/10/2022",
                    horaFim: "18:00",
                    dataRetirada: "20/10/2022",
                    horaRetirada: "20:00",
                    ano: "1",
                    mes: "2",
                    dia: "3",
                    hora: "5",
                    minuto: "35",
                    periodoCondicional: "1 ano 4 meses 3 dias"
                },
                {
                    seq: "2",
                    urlFoto: "https://espaco.blob.core.windows.net/nixloc-photo-product/noImage.jpg",
                    grupo: "Serviços",
                    categoria: "Ferramenta",
                    tipo: "Entrega",
                    modelo: "Capital",
                    acessorio: "Case (1) - Carregador (2)",
                    patrimonio: "P7899658 - P854745856",
                    descricao: "Vaio de 40KM",
                    qtd: 1,
                    qtdLocado: 0,
                    qtdRetornado: 1,
                    unMedida: "",
                    valorUnit: "R$ 150,00",
                    valorTotal: "R$ 150,00",
                    valorDescUnit: "R$ 0,00",
                    valorDescTotal: "R$ 0,00",
                    porcenDescUnit: "0%",
                    valorSeguroUnit: "",
                    valorSeguroTotal: "",
                    valorReposicaoUnit: "",
                    valorReposicaoTotal: "",
                    dataEntrega: "17/10/2022",
                    horaEntrega: "8:00",
                    dataInicio: "17/10/2022",
                    horaInicio: "9:00",
                    dataFim: "20/10/2022",
                    horaFim: "18:00",
                    dataRetirada: "20/10/2022",
                    horaRetirada: "20:00",
                    ano: "1",
                    mes: "2",
                    dia: "3",
                    hora: "5",
                    minuto: "35"
                }
            ],
            itensMovimentacao: [
                {
                    seq: "1",
                    urlFoto: "https://espaco.blob.core.windows.net/nixloc-photo-product/noImage.jpg",
                    grupo: "Equipamentos",
                    categoria: "Vídeo",
                    tipo: "Betoneira",
                    modelo: "400 Litros",
                    acessorio: "Tripé 2Mts (4) - Cabo de Energia 20Mts (4)",
                    patrimonio: "P442685 - P885549",
                    descricao: "A betoneira é um equipamento utilizado em canteiro de obra para realizar a mistura de concreto e argamassa. Confira o que são betoneiras, tipos e tamanhos. A betoneira é um equipamento utilizado em canteiros de obras para realizar a mistura de concreto e argamassa.",
                    qtd: 5,
                    unMedida: "Pça",
                    valorUnit: "R$ 100,00",
                    valorTotal: "R$ 180,00",
                    valorDescUnit: "R$ 10,00",
                    valorDescTotal: "R$ 20,00",
                    porcenDescUnit: "10%",
                    valorSeguroUnit: "R$ 5,00",
                    valorSeguroTotal: "R$ 10,00",
                    valorReposicaoUnit: "R$ 4.500,00",
                    valorReposicaoTotal: "R$ 9.000,00",
                    dataEntrega: "17/10/2022",
                    horaEntrega: "8:00",
                    dataInicio: "17/10/2022",
                    horaInicio: "9:00",
                    dataFim: "20/10/2022",
                    horaFim: "18:00",
                    dataRetirada: "20/10/2022",
                    horaRetirada: "20:00",
                    ano: "1",
                    mes: "2",
                    dia: "3",
                    hora: "5",
                    minuto: "35"
                },
                {
                    seq: "2",
                    urlFoto: "https://espaco.blob.core.windows.net/nixloc-photo-product/noImage.jpg",
                    grupo: "Serviços",
                    categoria: "Ferramenta",
                    tipo: "Entrega",
                    modelo: "Capital",
                    acessorio: "Case (1) - Carregador (2)",
                    patrimonio: "P7899658 - P854745856",
                    descricao: "Vaio de 40KM",
                    qtd: 5,
                    unMedida: "",
                    valorUnit: "R$ 150,00",
                    valorTotal: "R$ 150,00",
                    valorDescUnit: "R$ 0,00",
                    valorDescTotal: "R$ 0,00",
                    porcenDescUnit: "0%",
                    valorSeguroUnit: "",
                    valorSeguroTotal: "",
                    valorReposicaoUnit: "",
                    valorReposicaoTotal: "",
                    dataEntrega: "17/10/2022",
                    horaEntrega: "8:00",
                    dataInicio: "17/10/2022",
                    horaInicio: "9:00",
                    dataFim: "20/10/2022",
                    horaFim: "18:00",
                    dataRetirada: "20/10/2022",
                    horaRetirada: "20:00",
                    ano: "1",
                    mes: "2",
                    dia: "3",
                    hora: "5",
                    minuto: "35"
                }
            ],
            pagamentoLocacao: [
                {
                    seq: "1",
                    tipoPagamento: "Pix",
                    vencimento: "10/10/2022",
                    valor: "R$ 100,00",
                    descricao: "Entrada"
                },
                {
                    seq: "2",
                    tipoPagamento: "Boleto",
                    vencimento: "10/11/2022",
                    valor: "R$ 180,00",
                    descricao: "Referente a 80%"
                }
            ],
            saidaRetorno: [
                {
                    seq: "1",
                    descricao: "Principal",
                    endereco: "Avenida Gustavo Adolfo, 500 - Vila Gustavo - São Paulo - SP, 02209000",
                },
                {
                    seq: "2",
                    descricao: "Secundário",
                    endereco: "Av. Paulista, 500 - Bela Vista, São Paulo - SP, 01310-000",
                }
            ],
            pagarReceber: {
                "dataCadastro": "04/08/2023",
                "dataCadastroExtenso": "sexta-feira, 04 agosto 2023",
                "tipo": "Receber",
                "descricao": "Teste",
                "parcela": "",
                "documento": "1414",
                "dueDate": "2023-09-04T14:47:00",
                "valor": 40000,
                "valorExtenso": "Quarenta Mil Reais",
                "vencimento": "04/09/2023",
                "vencimentoExtenso": "segunda-feira, 04 setembro 2023",
                "competencia": "04/09/2023",
                "tipoDePagamento": "Dinheiro",
                "centroDeCusto": "Locação",
                "contaBancariaNome": "Itaú",
                "contaBancariaNumero": "Itau, Agência 4400-2 Conta 36600-1",
                "pago": "Sim",
                "dataConciliacao": "04/08/2023",
                "dataConciliacaoExtenso": "sexta-feira, 04 agosto 2023",
                "usuarioConciliacao": "user@teste.com.br",
                "desconto": 4000,
                "juros": 0,
                "observacoes": "Valor pago com desconto.",
                "cliente": {
                    "razaoSocialNome": "Empresa Teste Ltda",
                    "fantasiaApelido": "Empresa Teste",
                    "cnpjCpfTitulo": "CNPJ",
                    "irTitulo": "Inscrição Estadual",
                    "cnpjCpf": "00.858.000/0001-88",
                    "ir": "111.111.000",
                    "im": "222.222.333",
                    "cep": "05668000",
                    "rua": "Rua Santana",
                    "numero": "181",
                    "complemento": "Galpão 3",
                    "bairro": "Jardim São Paulo",
                    "cidade": "São Paulo",
                    "estado": "SP",
                    "telefone": "(11) 0000-00000",
                    "site": "www.empresateste.com.br",
                    "email": "contato@teste.com.br",
                    "obs": "Empresa de audiovisual",
                },
            },
            itensOs: [
                {
                    seq: "1",
                    urlFoto: "https://espaco.blob.core.windows.net/nixloc-photo-product/noImage.jpg",
                    categoria: "Vídeo",
                    tipo: "Betoneira",
                    modelo: "400 Litros",
                    patrimonio: "P442685",
                    descricao: "A betoneira é um equipamento utilizado em canteiro de obra para realizar a mistura de concreto e argamassa. Confira o que são betoneiras, tipos e tamanhos. A betoneira é um equipamento utilizado em canteiros de obras para realizar a mistura de concreto e argamassa.",
                    qtd: 2,
                    unMedida: "Pça",
                    valorUnit: "R$ 100,00",
                    valorTotal: "R$ 180,00",
                    dataInicio: "17/10/2022",
                    dataFim: "20/10/2022",
                },
            ],
            equipe: [{
                colaborador:
                {
                    urlFoto: "https://espaco.blob.core.windows.net/nixloc-photo-collaborator/noImage.png",
                    nome: "João da Silva",
                    apelido: "João",
                    cpf: "111.111.111-11",
                    rg: "11.111.111-99",
                    cep: "0000-000",
                    rua: "Rua Exemplo",
                    numero: "111",
                    complemento: "Galpão 2",
                    bairro: "Exemplo",
                    cidade: "Natal",
                    estado: "SP",
                    telefone: "(11) 11111-1111",
                    site: "www.exemplo.com.br",
                    email: "teste@exemplo.com.br",
                    obs: "Especialista em Vídeo"
                },
                dataInicio: "07/09/2023",
                horaInicio: "08:30",
                dataFim: "07/09/2023",
                horaFim: "17:33",
                descricao: "Será Responsável pelo Vídeo",
                dataPagamento: "26/09/2023",
                valor: "R$ 100,00",
                valorExtenso: "Cem Reais"
            }],
            empresa: {
                urlLogo: "https://espaco.blob.core.windows.net/nixloc-logo-company/noImage.jpg",
                razaoSocialNome: "Sua Locadora Ltda",
                fantasiaApelido: "Sua Locadora",
                cnpjCpf: "00.000.000/0001-00",
                ir: "000.000.000-00",
                im: "000.000.000-00",
                telefone: "(00) 0000-0000",
                site: "www.sualocadora.com.br",
                email: "email@sualocadora.com.br",
                cep: "00000-000",
                rua: "Rua da Sua Locadora",
                numero: "20",
                complemento: "Galpão 15",
                bairro: "Chacará",
                cidade: "Cotia",
                estado: "SP"
            }
        }
    }
}
