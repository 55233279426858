<template>
  <div>
    <ViewTemplateDocumentView :panel="panel" :parameterExemple="parameterExemple.d"
      :parameterLegend="parameterLegend.legend" :module="module" :styleCss="styleCss">
      <DocumentCreateUpdate v-model="module" />
    </ViewTemplateDocumentView>
  </div>
</template>

<script>
import DocumentCreateUpdate from "../../../components/modules/adm/document/DocumentCreateUpdate.vue";
import ViewTemplateDocumentView from "@nixweb/nixloc-ui/src/component/template/ViewTemplateDocumentView";

import ParameterExemple from "./model/ParameterExemple.js";
import ParameterLegend from "./model/ParameterLegend.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "DocumentCreateUpdateView",
  components: { ViewTemplateDocumentView, DocumentCreateUpdate },
  data() {
    return {
      id: this.$route.params.id,
      module: "",
      urlGetById: "/api/v1/adm/document/get-by-id",
      panel: {
        module: "Adm",
        title: "Documento",
        showFilter: false,
        showSearch: false,
        showButtons: false,
      },
      parameterExemple: new ParameterExemple(),
      parameterLegend: new ParameterLegend(),
      styleCss: "",
    };
  },
  mounted() {
    if (this.id) {
      this.getById();
    } else {
      let self = this;
      setTimeout(function () {
        self.updateDocumentHtml("");
        self.removeLoading(["panel"]);
      }, 100);
    }
    this.removeLoading(["createDocument"]);
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    ...mapMutations("generic", [
      "addEvent",
      "removeLoading",
      "updateDocumentHtml",
    ]),
    getById() {
      let params = { url: this.urlGetById, obj: { documentId: this.id } };
      this.getApi(params).then((response) => {
        this.styleCss = response.content.styleCss;
        this.updateDocumentHtml(response.content.documentHtml);
        this.addEvent({
          name: "updateDocument",
          data: response.content,
        });
        let self = this;
        setTimeout(function () {
          self.removeLoading(["panel"]);
        }, 500);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "documentEditorModified") {
          this.getById();
        }
      },
      deep: true,
    },
  },
};
</script>
