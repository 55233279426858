export default class LegendParameter {
    constructor() {
        this.legend = [{
            groupName: "COMUM",
            parameter: "{{d.comum.dataAtual}}",
            description: "Data Atual",
            example: "15/10/2022",
            showModule: "all"
        },
        {
            groupName: "COMUM",
            parameter: "{{d.comum.dataAtualExtenso}}",
            description: "Data Atual por Extenso",
            example: "sábado, 15 outubro 2022",
            showModule: "all",
        },
        {
            groupName: "COMUM",
            parameter: "{{d.comum.horaAtual}}",
            description: "Hora Atual",
            example: "17:00",
            showModule: "all"
        },
        {
            groupName: "LOCAÇÃO",
            parameter: "{{d.locacao.numero}}",
            description: "Número da Locação",
            example: "900",
            showModule: "Rental PDV Billing"
        },
        {
            groupName: "LOCAÇÃO",
            parameter: "{{d.locacao.dataCadastro}}",
            description: "Data de Cadastro",
            example: "14/10/2022",
            showModule: "Rental PDV Billing"
        },
        {
            groupName: "LOCAÇÃO",
            parameter: "{{d.locacao.horaCadastro}}",
            description: "Hora de Cadastro",
            example: "16:00",
            showModule: "Rental PDV Billing"
        },
        {
            groupName: "LOCAÇÃO",
            parameter: "{{d.locacao.dataEfetivacao}}",
            description: "Data de Efetivação",
            example: "14/10/2022",
            showModule: "Rental PDV Billing"
        },
        {
            groupName: "LOCAÇÃO",
            parameter: "{{d.locacao.identificacao}}",
            description: "Identificação",
            example: "Locação Evento Brasil",
            showModule: "Rental PDV Billing"
        },
        {
            groupName: "LOCAÇÃO",
            parameter: "{{d.locacao.tipoLocacao}}",
            description: "Tipo",
            example: "Evento Interno",
            showModule: "Rental PDV Billing"
        },
        {
            groupName: "LOCAÇÃO",
            parameter: "{{d.locacao.obsLocacao}}",
            description: "Observações da Locação",
            example: "Valido por 7 dia(s)",
            showModule: "Rental PDV Billing"
        },
        {
            groupName: "LOCAÇÃO",
            parameter: "{{d.locacao.obsOs}}",
            description: "Observações da Ordem de Serviço",
            example: "Entregar para Sr João",
            showModule: "Rental PDV Billing"
        },
        {
            groupName: "LOCAÇÃO",
            parameter: "{{d.locacao.total}}",
            description: "Total",
            example: "R$ 1.600,00",
            showModule: "Rental PDV Billing"
        },
        {
            groupName: "LOCAÇÃO",
            parameter: "{{d.locacao.totalExtenso}}",
            description: "Total Extenso",
            example: "Um Mil e Seiscentos Reais",
            showModule: "Rental PDV Billing"
        },
        {
            groupName: "LOCAÇÃO",
            parameter: "{{d.locacao.totalCusto}}",
            description: "Total Custo",
            example: "R$ 800,00",
            showModule: "Rental Billing"
        },
        {
            groupName: "LOCAÇÃO",
            parameter: "{{d.locacao.totalCustoExtenso}}",
            description: "Total Custo por Extenso",
            example: "Oitocentos Reais",
            showModule: "Rental Billing"
        },
        {
            groupName: "LOCAÇÃO",
            parameter: "{{d.locacao.totalReposicao}}",
            description: "Total Reposição",
            example: "R$ 20.000,00",
            showModule: "Rental Billing"
        },
        {
            groupName: "LOCAÇÃO",
            parameter: "{{d.locacao.totalReposicaoExtenso}}",
            description: "Total Reposição por Extenso",
            example: "Vinte Mil Reais",
            showModule: "Rental Billing"
        },
        {
            groupName: "LOCAÇÃO",
            parameter: "{{d.locacao.totalDesconto}}",
            description: "Total Desconto",
            example: "R$ 100,00",
            showModule: "Rental PDV Billing"
        },
        {
            groupName: "LOCAÇÃO",
            parameter: "{{d.locacao.totalDescontoExtenso}}",
            description: "Total Desconto por Extenso",
            example: "Cem Reais",
            showModule: "Rental PDV Billing"
        },
        {
            groupName: "LOCAÇÃO",
            parameter: "{{d.locacao.totalSeguro}}",
            description: "Total Seguro",
            example: "R$ 20,00",
            showModule: "Rental PDV Billing"
        },
        {
            groupName: "LOCAÇÃO",
            parameter: "{{d.locacao.totalSeguroExtenso}}",
            description: "Total Seguro por Extenso",
            example: "Vinte Reais",
            showModule: "Rental PDV Billing"
        },
        {
            groupName: "LOCAÇÃO",
            parameter: "{{d.locacao.totalGeral}}",
            description: "Total Geral",
            example: "R$ 1.520,00",
            showModule: "Rental PDV Billing"
        },
        {
            groupName: "LOCAÇÃO",
            parameter: "{{d.locacao.totalGeralExtenso}}",
            description: "Total Geral por Extenso",
            example: "Um Mil e Quinhentos E Vinte Reais",
            showModule: "Rental PDV Billing"
        },
        {
            groupName: "CLIENTE DA LOCAÇÃO",
            parameter: "{{d.locacao.cliente.razaoSocialNome}}",
            description: "Razão Social/Nome",
            example: "Grupo Locadora Exemplo Ltda",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA LOCAÇÃO",
            parameter: "{{d.locacao.cliente.fantasiaApelido}}",
            description: "Fantasia/Apelido",
            example: "Locadora Exemplo",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA LOCAÇÃO",
            parameter: "{{d.locacao.cliente.cnpjCpfTitulo}}",
            description: "CNPJ ou CPF",
            example: "CNPJ",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA LOCAÇÃO",
            parameter: "{{d.locacao.cliente.cnpjCpf}}",
            description: "Cnpj ou Cpf",
            example: "00.000.000/0001-00",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA LOCAÇÃO",
            parameter: "{{d.locacao.cliente.irTitulo}}",
            description: "Inscrição Estadual ou RG",
            example: "Inscrição Estadual",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA LOCAÇÃO",
            parameter: "{{d.locacao.cliente.ir}}",
            description: "Inscrição Estadual ou RG",
            example: "000.000.000-00",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA LOCAÇÃO",
            parameter: "{{d.locacao.cliente.im}}",
            description: "Inscrição Municipal",
            example: "000.000.000-00",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA LOCAÇÃO",
            parameter: "{{d.locacao.cliente.cep}}",
            description: "CEP",
            example: "00000-000",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA LOCAÇÃO",
            parameter: "{{d.locacao.cliente.rua}}",
            description: "Rua",
            example: "Rua do Cliente 1",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA LOCAÇÃO",
            parameter: "{{d.locacao.cliente.numero}}",
            description: "Número",
            example: "100",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA LOCAÇÃO",
            parameter: "{{d.locacao.cliente.complemento}}",
            description: "Complemento",
            example: "Galpão 2",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA LOCAÇÃO",
            parameter: "{{d.locacao.cliente.bairro}}",
            description: "Bairro",
            example: "Butantã",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA LOCAÇÃO",
            parameter: "{{d.locacao.cliente.cidade}}",
            description: "Cidade",
            example: "Cotia",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA LOCAÇÃO",
            parameter: "{{d.locacao.cliente.estado}}",
            description: "Estado",
            example: "SP",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA LOCAÇÃO",
            parameter: "{{d.locacao.cliente.telefone}}",
            description: "Telefone",
            example: "(00) 0000-0000",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA LOCAÇÃO",
            parameter: "{{d.locacao.cliente.site}}",
            description: "Site",
            example: "www.locadoraexemplo.com.br",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA LOCAÇÃO",
            parameter: "{{d.locacao.cliente.email}}",
            description: "E-mail",
            example: "email@locadoraexemplo.com.br",
            showModule: "all"
        },
        {
            groupName: "COLABORADOR DA LOCAÇÃO",
            parameter: "{{d.locacao.colaborador.nome}}",
            description: "Nome",
            example: "João da Silva",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "COLABORADOR DA LOCAÇÃO",
            parameter: "{{d.locacao.colaborador.apelido}}",
            description: "Nome",
            example: "João",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "COLABORADOR DA LOCAÇÃO",
            parameter: "{{d.locacao.colaborador.celular}}",
            description: "Celular",
            example: "(00) 9000-0000",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "COLABORADOR DA LOCAÇÃO",
            parameter: "{{d.locacao.colaborador.email}}",
            description: "E-mail",
            example: "email@consultor.com.br",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "CONTATO DA LOCAÇÃO",
            parameter: "{{d.locacao.contato.nome}}",
            description: "Nome",
            example: "Maria da Silva",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "CONTATO DA LOCAÇÃO",
            parameter: "{{d.locacao.contato.celular}}",
            description: "Celular",
            example: "(00) 9000-0000",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "CONTATO DA LOCAÇÃO",
            parameter: "{{d.locacao.contato.email}}",
            description: "E-mail",
            example: "email@contato.com.br",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "CONTATO DA LOCAÇÃO",
            parameter: "{{d.locacao.cliente.obs}}",
            description: "Observações",
            example: "Cliente 5 estrelas",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "FATURA",
            parameter: "{{d.fatura.numero}}",
            description: "Número da Fatura",
            example: "20",
            showModule: "Billing"
        },
        {
            groupName: "FATURA",
            parameter: "{{d.fatura.dataEmissao}}",
            description: "Data de Emissão",
            example: "20/10/2022",
            showModule: "Billing"
        },
        {
            groupName: "FATURA",
            parameter: "{{d.fatura.obs}}",
            description: "Observações",
            example: "Referente a Locação 42",
            showModule: "Billing"
        },
        {
            groupName: "FATURA",
            parameter: "{{d.fatura.total}}",
            description: "Total",
            example: "R$ 1.000.00",
            showModule: "Billing"
        },
        {
            groupName: "FATURA",
            parameter: "{{d.fatura.totalExtenso}}",
            description: "Total por Extenso",
            example: "Um mil reais",
            showModule: "Billing"
        },
        {
            groupName: "FATURA",
            parameter: "{{d.fatura.totalDesconto}}",
            description: "Total Desconto",
            example: "R$ 100,00",
            showModule: "Billing"
        },
        {
            groupName: "FATURA",
            parameter: "{{d.fatura.totalDescontoExtenso}}",
            description: "Total Desconto por Extenso",
            example: "Cem Reais",
            showModule: "Billing"
        },
        {
            groupName: "FATURA",
            parameter: "{{d.fatura.totalGeral}}",
            description: "Total Geral",
            example: "R$ 1.520,00",
            showModule: "Billing"
        },
        {
            groupName: "FATURA",
            parameter: "{{d.fatura.totalGeralExtenso}}",
            description: "Total Geral por Extenso",
            example: "Um Mil e Quinhentos E Vinte Reais",
            showModule: "Billing"
        },
        {
            groupName: "CLIENTE DA FATURA",
            parameter: "{{d.locacao.fatura.razaoSocialNome}}",
            description: "Razão Social/Nome",
            example: "Grupo Locadora Exemplo Ltda",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA FATURA",
            parameter: "{{d.locacao.fatura.fantasiaApelido}}",
            description: "Fantasia/Apelido",
            example: "Locadora Exemplo",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA FATURA",
            parameter: "{{d.fatura.cliente.cnpjCpfTitulo}}",
            description: "CNPJ ou CPF",
            example: "CNPJ",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA FATURA",
            parameter: "{{d.fatura.cliente.cnpjCpf}}",
            description: "Cnpj ou Cpf",
            example: "00.000.000/0001-00",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA FATURA",
            parameter: "{{d.fatura.cliente.irTitulo}}",
            description: "Inscrição Estadual ou RG",
            example: "Inscrição Estadual",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA FATURA",
            parameter: "{{d.fatura.cliente.ir}}",
            description: "Inscrição Estadual ou RG",
            example: "000.000.000-00",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA FATURA",
            parameter: "{{d.fatura.cliente.im}}",
            description: "Inscrição Municipal",
            example: "000.000.000-00",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA FATURA",
            parameter: "{{d.fatura.cliente.cep}}",
            description: "CEP",
            example: "00000-000",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA FATURA",
            parameter: "{{d.fatura.cliente.rua}}",
            description: "Rua",
            example: "Rua do Cliente 1",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA FATURA",
            parameter: "{{d.fatura.cliente.numero}}",
            description: "Número",
            example: "100",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA FATURA",
            parameter: "{{d.fatura.cliente.complemento}}",
            description: "Complemento",
            example: "Galpão 2",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA FATURA",
            parameter: "{{d.fatura.cliente.bairro}}",
            description: "Bairro",
            example: "Butantã",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA FATURA",
            parameter: "{{d.fatura.cliente.cidade}}",
            description: "Cidade",
            example: "Cotia",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA FATURA",
            parameter: "{{d.fatura.cliente.estado}}",
            description: "Estado",
            example: "SP",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA FATURA",
            parameter: "{{d.fatura.cliente.telefone}}",
            description: "Telefone",
            example: "(00) 0000-0000",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA FATURA",
            parameter: "{{d.fatura.cliente.site}}",
            description: "Site",
            example: "www.locadoraexemplo.com.br",
            showModule: "all"
        },
        {
            groupName: "CLIENTE DA FATURA",
            parameter: "{{d.fatura.cliente.email}}",
            description: "E-mail",
            example: "email@locadoraexemplo.com.br",
            showModule: "all"
        },
        {
            groupName: "ENDEREÇOS DO CLIENTE (t.enderecoCliente)",
            parameter: "{{endereco.descricao}}",
            description: "Descrição",
            example: "Principal",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "ENDEREÇOS DO CLIENTE (t.enderecoCliente)",
            parameter: "{{endereco.seq}}",
            description: "Sequencial",
            example: "1",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "ENDEREÇOS DO CLIENTE (t.enderecoCliente)",
            parameter: "{{endereco.cep}}",
            description: "CEP",
            example: "00000-000",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "ENDEREÇOS DO CLIENTE (t.enderecoCliente)",
            parameter: "{{endereco.rua}}",
            description: "Rua",
            example: "Rua do Cliente 1",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "ENDEREÇOS DO CLIENTE (t.enderecoCliente)",
            parameter: "{{endereco.numero}}",
            description: "Número",
            example: "100",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "ENDEREÇOS DO CLIENTE (t.enderecoCliente)",
            parameter: "{{endereco.complemento}}",
            description: "Complemento",
            example: "Galpão 2",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "ENDEREÇOS DO CLIENTE (t.enderecoCliente)",
            parameter: "{{endereco.bairro}}",
            description: "Bairro",
            example: "Butantã",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "ENDEREÇOS DO CLIENTE (t.enderecoCliente)",
            parameter: "{{endereco.cidade}}",
            description: "Cidade",
            example: "Cotia",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "ENDEREÇOS DO CLIENTE (t.enderecoCliente)",
            parameter: "{{endereco.estado}}",
            description: "Estado",
            example: "SP",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PERIODO(S) DA LOCAÇÃO (t.periodoLocacao)",
            parameter: "{{periodo.seq}}",
            description: "Sequencial",
            example: "1",
            showModule: "Rental PDV"
        },
        {
            groupName: "PERIODO(S) DA LOCAÇÃO (t.periodoLocacao)",
            parameter: "{{periodo.dataEntrega}}",
            description: "Data de Entrega",
            example: "17/10/2022",
            showModule: "Rental PDV"
        },
        {
            groupName: "PERIODO(S) DA LOCAÇÃO (t.periodoLocacao)",
            parameter: "{{periodo.horaEntrega}}",
            description: "Hora de Entrega",
            example: "8:00",
            showModule: "Rental PDV"
        },
        {
            groupName: "PERIODO(S) DA LOCAÇÃO (t.periodoLocacao)",
            parameter: "{{periodo.dataInicio}}",
            description: "Data de Inicio",
            example: "17/10/2022",
            showModule: "Rental PDV"
        },
        {
            groupName: "PERIODO(S) DA LOCAÇÃO (t.periodoLocacao)",
            parameter: "{{periodo.horaInicio}}",
            description: "Hora de Inicio",
            example: "9:00",
            showModule: "Rental PDV"
        },
        {
            groupName: "PERIODO(S) DA LOCAÇÃO (t.periodoLocacao)",
            parameter: "{{periodo.dataFim}}",
            description: "Data Fim",
            example: "20/10/2022",
            showModule: "Rental PDV"
        },
        {
            groupName: "PERIODO(S) DA LOCAÇÃO (t.periodoLocacao)",
            parameter: "{{periodo.horaFim}}",
            description: "Hora de Inicio",
            example: "9:00",
            showModule: "Rental PDV"
        },
        {
            groupName: "PERIODO(S) DA LOCAÇÃO (t.periodoLocacao)",
            parameter: "{{periodo.dataRetirada}}",
            description: "Data de Retirada",
            example: "20/10/2022",
            showModule: "Rental PDV"
        },
        {
            groupName: "PERIODO(S) DA LOCAÇÃO (t.periodoLocacao)",
            parameter: "{{periodo.horaRetirada}}",
            description: "Hora de Retirada",
            example: "20:00",
            showModule: "Rental PDV"
        },
        {
            groupName: "PERIODO(S) DA LOCAÇÃO (t.periodoLocacao)",
            parameter: "{{periodo.ano}}",
            description: "Ano(s)",
            example: "1",
            showModule: "Rental PDV"
        },
        {
            groupName: "PERIODO(S) DA LOCAÇÃO (t.periodoLocacao)",
            parameter: "{{periodo.mes}}",
            description: "Mês",
            example: "2",
            showModule: "Rental PDV"
        },
        {
            groupName: "PERIODO(S) DA LOCAÇÃO (t.periodoLocacao)",
            parameter: "{{periodo.dia}}",
            description: "Dia",
            example: "3",
            showModule: "Rental PDV"
        },
        {
            groupName: "PERIODO(S) DA LOCAÇÃO (t.periodoLocacao)",
            parameter: "{{periodo.hora}}",
            description: "Hora",
            example: "5",
            showModule: "Rental PDV"
        },
        {
            groupName: "PERIODO(S) DA LOCAÇÃO (t.periodoLocacao)",
            parameter: "{{periodo.minuto}}",
            description: "Minuto",
            example: "35",
            showModule: "Rental PDV"
        },
        {
            groupName: "PERIODO(S) DA LOCAÇÃO (t.periodoLocacao)",
            parameter: "{{periodo.periodoCondicional}}",
            description: "Período completo",
            example: "1 ano 4 meses 3 dias",
            showModule: "Rental PDV"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.seq}}",
            description: "Sequencial",
            example: "1",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "<img :src='produto.urlFoto' width='100' height='100'>",
            description: "Url foto",
            example: "Foto (Padrão 100x100)",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.grupo}}",
            description: "Nome do Grupo",
            example: "Equipamentos",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.categoria}}",
            description: "Categoria",
            example: "Ferramenta",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.tipo}}",
            description: "Tipo",
            example: "Betoneira",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.modelo}}",
            description: "Modelo",
            example: "400 Litros",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.acessorio}}",
            description: "Acessório(s) vinculado(s)",
            example: "Tripé 2Mts (4) - Cabo de Energia 20Mts (4)",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.patrimonio}}",
            description: "Patrimônio(s) vinculado(s)",
            example: "P442685 - P885549",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.descricao}}",
            description: "Descrição",
            example: "A betoneira é um equipamento utilizado em canteiro de obra para realizar a mistura de concreto e argamassa.",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.qtd}}",
            description: "Quantidade",
            example: "1",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.qtdLocado}}",
            description: "Quantidade Locada",
            example: "1",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.qtdRetornado}}",
            description: "Quantidade Retornada",
            example: "1",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.unMedida}}",
            description: "Unidade de Medida",
            example: "Pça",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.valorUnit}}",
            description: "Valor Unitário",
            example: "R$ 100,00",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.valorUnitComDesconto}}",
            description: "Valor Unitário com Desconto",
            example: "R$ 10,00",
            showModule: "Rental"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.valorTotal}}",
            description: "Valor Total",
            example: "R$ 180,00",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.valorDescUnit}}",
            description: "Valor Desconto Unitário",
            example: "R$ 10,00",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.valorDescTotal}}",
            description: "Valor Desconto Total",
            example: "R$ 20,00",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.porcenDescUnit}}",
            description: "Valor Desconto em %",
            example: "10%",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.valorSeguroUnit}}",
            description: "Valor Seguro Unitário",
            example: "R$ 5,00",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.valorSeguroTotal}}",
            description: "Valor Seguro Total",
            example: "R$ 10,00",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.valorReposicaoUnit}}",
            description: "Valor Reposição Unitário",
            example: "R$ 4.500,00",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.valorReposicaoTotal}}",
            description: "Valor Reposição Total",
            example: "R$ 9.000,00",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.dataEntrega}}",
            description: "Data de Entrega",
            example: "17/10/2022",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.horaEntrega}}",
            description: "Hora de Entrega",
            example: "8:00",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.dataInicio}}",
            description: "Data de Inicio",
            example: "17/10/2022",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.horaInicio}}",
            description: "Hora de Inicio",
            example: "9:00",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.dataFim}}",
            description: "Data Fim",
            example: "20/10/2022",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.horaFim}}",
            description: "Hora de Inicio",
            example: "9:00",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.dataRetirada}}",
            description: "Data de Retirada",
            example: "20/10/2022",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.horaRetirada}}",
            description: "Hora de Retirada",
            example: "20:00",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.ano}}",
            description: "Ano(s)",
            example: "1",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.mes}}",
            description: "Mês",
            example: "2",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.dia}}",
            description: "Dia",
            example: "3",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.hora}}",
            description: "Hora",
            example: "5",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.minuto}}",
            description: "Minuto",
            example: "35",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO (t.produtoLocacao)",
            parameter: "{{produto.periodoCondicional}}",
            description: "Período completo",
            example: "1 ano 4 meses 3 dias",
            showModule: "Rental PDV MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO POR GRUPO (t.produtoAgrupado)",
            parameter: "{{grupo}}",
            description: "Nome do Grupo",
            example: "Equipamentos",
            showModule: "Rental PDV"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO POR GRUPO (t.produtoAgrupado)",
            parameter: "",
            description: "",
            example: "Todos os parâmetros do PRODUTO(S) DA LOCAÇÃO",
            showModule: "Rental PDV"
        },
        {
            groupName: "PRODUTO(S) DA LOCAÇÃO POR GRUPO (t.produtoAgrupado)",
            parameter: "{{somaGrupo(grupo) | currency}}",
            description: "Soma do Grupo",
            example: "R$ 150,00",
            showModule: "Rental PDV"
        },
        {
            groupName: "PRODUTO(S) DA MOVIMENTAÇÃO (t.produtoMovimentacao)",
            parameter: "",
            description: "",
            example: "Todos os parâmetros do PRODUTO(S) DA LOCAÇÃO",
            showModule: "MovementStock"
        },
        {
            groupName: "PRODUTO(S) DA MOVIMENTAÇÃO (t.produtoMovimentacao)",
            parameter: "{{produto.qtd}}",
            description: "Quantidade",
            example: "5",
            showModule: "MovementStock"
        },
        {
            groupName: "PAGAMENTO(S) (t.pagamentoLocacao)",
            parameter: "{{pagamento.seq}}",
            description: "Sequencial",
            example: "1",
            showModule: "Rental PDV"
        },
        {
            groupName: "PRODUTO(S) DA FATURA (t.produtoFatura)",
            parameter: "{{produto.Descricao}}",
            description: "Descrição",
            example: "Referente a Locação de Equipamentos",
            showModule: "Billing"
        },
        {
            groupName: "PRODUTO(S) DA FATURA (t.produtoFatura)",
            parameter: "{{produto.quantidade}}",
            description: "Quantidade",
            example: "1",
            showModule: "Billing"
        },
        {
            groupName: "PRODUTO(S) DA FATURA (t.produtoFatura)",
            parameter: "{{produto.valorUnit}}",
            description: "Valor Unitário",
            example: "1",
            showModule: "Billing"
        },
        {
            groupName: "PRODUTO(S) DA FATURA (t.produtoFatura)",
            parameter: "{{produto.valorTotal}}",
            description: "Valor Total",
            example: "R$ 180,00",
            showModule: "Billing"
        },
        {
            groupName: "PRODUTO(S) DA FATURA (t.produtoFatura)",
            parameter: "{{produto.valorDescUnit}}",
            description: "Valor Desconto Unitário",
            example: "R$ 10,00",
            showModule: "Billing"
        },
        {
            groupName: "PRODUTO(S) DA FATURA (t.produtoFatura)",
            parameter: "{{produto.valorDescTotal}}",
            description: "Valor Desconto Total",
            example: "R$ 20,00",
            showModule: "Billing"
        },
        {
            groupName: "PAGAMENTO(S) (t.pagamentoLocacao)",
            parameter: "{{pagamento.tipoPagamento}}",
            description: "Tipo de Pagamento",
            example: "Pix",
            showModule: "Rental PDV"
        },
        {
            groupName: "PAGAMENTO(S) (t.pagamentoLocacao)",
            parameter: "{{pagamento.vencimento}}",
            description: "Vencimento",
            example: "10/10/2022",
            showModule: "Rental PDV"
        },
        {
            groupName: "PAGAMENTO(S) (t.pagamentoLocacao)",
            parameter: "{{pagamento.valor}}",
            description: "Valor",
            example: "R$ 100,00",
            showModule: "Rental PDV"
        },
        {
            groupName: "PAGAMENTO(S) (t.pagamentoLocacao)",
            parameter: "{{pagamento.descricao}}",
            description: "Descrição",
            example: "Entrada 50%",
            showModule: "Rental PDV"
        },
        {
            groupName: "SAÍDA/RETORNO DA LOCAÇÃO (t.saidaRetornoLocacao)",
            parameter: "{{endereco.seq}}",
            description: "Sequencial",
            example: "1",
            showModule: "Rental PDV"
        },
        {
            groupName: "SAÍDA/RETORNO DA LOCAÇÃO (t.saidaRetornoLocacao)",
            parameter: "{{endereco.descricao}}",
            description: "Descrição",
            example: "Principal",
            showModule: "Rental PDV"
        },
        {
            groupName: "SAÍDA/RETORNO DA LOCAÇÃO (t.saidaRetornoLocacao)",
            parameter: "{{endereco.endereco}}",
            description: "Endereço",
            example: "Avenida Gustavo Adolfo, 500 - Vila Gustavo - São Paulo - SP, 02209000",
            showModule: "Rental PDV"
        },
        {
            groupName: "EMPRESA",
            parameter: "<img :src='d.empresa.urlLogo' width='300' height='100'>",
            description: "Url Logo",
            example: "Logo (Padrão 300px X 100px)",
            showModule: "all"
        },
        {
            groupName: "EMPRESA",
            parameter: "{{d.empresa.razaoSocialNome}}",
            description: "Razão Social/Nome",
            example: "Sua Locadora Ltda",
            showModule: "all"
        },
        {
            groupName: "EMPRESA",
            parameter: "{{d.empresa.fantasiaApelido}}",
            description: "Fantasia/Apelido",
            example: "Sua Locadora",
            showModule: "all"
        },
        {
            groupName: "EMPRESA",
            parameter: "{{d.empresa.cnpjCpf}}",
            description: "Cnpj ou Cpf",
            example: "00.000.000/0001-00",
            showModule: "all"
        },
        {
            groupName: "EMPRESA",
            parameter: "{{d.empresa.ir}}",
            description: "Inscrição Estadual ou RG",
            example: "000.000.000-00",
            showModule: "all"
        },
        {
            groupName: "EMPRESA",
            parameter: "{{d.empresa.im}}",
            description: "Inscrição Municipal",
            example: "000.000.000-00",
            showModule: "all"
        },
        {
            groupName: "EMPRESA",
            parameter: "{{d.empresa.telefone}}",
            description: "Telefone",
            example: "(00) 0000-0000",
            showModule: "all"
        },
        {
            groupName: "EMPRESA",
            parameter: "{{d.empresa.site}}",
            description: "Site",
            example: "www.sualocadora.com.br",
            showModule: "all"
        },
        {
            groupName: "EMPRESA",
            parameter: "{{d.empresa.email}}",
            description: "E-mail",
            example: "email@sualocadora.com.br",
            showModule: "all"
        },
        {
            groupName: "EMPRESA",
            parameter: "{{d.empresa.cep}}",
            description: "CEP",
            example: "00000-000",
            showModule: "all"
        },
        {
            groupName: "EMPRESA",
            parameter: "{{d.empresa.rua}}",
            description: "Rua",
            example: "Rua da Sua Locadora",
            showModule: "all"
        },
        {
            groupName: "EMPRESA",
            parameter: "{{d.empresa.numero}}",
            description: "Número",
            example: "20",
            showModule: "all"
        },
        {
            groupName: "EMPRESA",
            parameter: "{{d.empresa.complemento}}",
            description: "Complemento",
            example: "Galpão 15",
            showModule: "all"
        },
        {
            groupName: "EMPRESA",
            parameter: "{{d.empresa.bairro}}",
            description: "Bairro",
            example: "Butantã",
            showModule: "all"
        },
        {
            groupName: "EMPRESA",
            parameter: "{{d.empresa.cidade}}",
            description: "Cidade",
            example: "Cotia",
            showModule: "all"
        },
        {
            groupName: "EMPRESA",
            parameter: "{{d.empresa.estado}}",
            description: "Estado",
            example: "SP",
            showModule: "all"
        },
        ]
    }
}
