export default class Document {
    constructor() {
        this.id = "";
        this.identification = "";
        this.name = "";
        this.module = [];
        this.container = "nixloc-document-v2";
        this.config = { product: { group: false, orderBy: 'default', show: [] }, moviment: { group: false, orderBy: 'default', show: [] } };
        this.imageWatermark = "";
        this.imageWidth = 0;
        this.imageHeight = 0;
        this.verticalAlignment = 0;
        this.horizontalAlignment = 1;
        this.opacity = 0;
        this.styleCss = "";
        this.showPages = false;
        this.marginTop = 0;
        this.marginLeft = 0;
        this.marginRight = 0;
        this.marginBottom = 0;
    }
    update(data) {
        this.id = data.id;
        this.identification = data.identification;
        this.name = data.name;
        this.module = data.module;
        this.config = data.config;
        this.imageWatermark = data.imageWatermark;
        this.imageWidth = data.imageWidth;
        this.imageHeight = data.imageHeight;
        this.verticalAlignment = data.verticalAlignment;
        this.horizontalAlignment = data.horizontalAlignment;
        this.opacity = data.opacity;
        this.styleCss = data.styleCss;
        this.showPages = data.showPages;
        this.marginTop = data.marginTop;
        this.marginLeft = data.marginLeft;
        this.marginRight = data.marginRight;
        this.marginBottom = data.marginBottom;
    }
}